import {
  IWixBookingAPI,
  AvailabilityOptions,
  GetServiceAvailabilityResponse,
  BookingInfo,
  CheckoutOptions,
  // CheckoutBookingStatus,
  // WIX_PAY_SUCCESSFUL_STATUS,
  // CheckoutBookingResult,
} from '../utils/types/types';
import {
  dateToEndOfDayDate,
  dateToStartOfDayDate,
} from '../utils/dateAndTime/dateAndTime';
import { mapAvailabilityServerError } from '../utils/errors/errors';

export class WixBookingsCodeAPI {
  private readonly wixCodeApi: IWixBookingAPI;

  constructor({ wixCodeApi }: { wixCodeApi: IWixBookingAPI }) {
    this.wixCodeApi = wixCodeApi;
  }

  async getServiceAvailability({
    serviceId,
    selectedDate,
  }: {
    serviceId: string;
    selectedDate: Date;
  }) {
    try {
      const options = this.getOptions(selectedDate);
      const availability =
        (await this.wixCodeApi.bookings.getServiceAvailability(
          serviceId,
          options,
        )) as GetServiceAvailabilityResponse;
      return availability;
    } catch (e) {
      console.log('Error: ', e);
      mapAvailabilityServerError(e);
      return null;
    }
  }
  async checkoutBooking({
    wixCodeApi,
    bookingInfo,
    checkoutOptions,
  }: {
    wixCodeApi: IWixBookingAPI;
    bookingInfo: BookingInfo;
    checkoutOptions: CheckoutOptions;
  }) {
    return wixCodeApi.bookings?.checkoutBooking(bookingInfo, checkoutOptions);
  }

  private getOptions = (date: Date): AvailabilityOptions => {
    const timezone: string = this.wixCodeApi.site.timezone!;
    const endDate = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate() + 30,
    );
    return {
      startDateTime: dateToStartOfDayDate({ date, timeZone: timezone }),
      endDateTime: dateToEndOfDayDate({ date: endDate, timeZone: timezone }),
    };
  };
}
