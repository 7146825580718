export type CurrencyFormatName = 'symbol' | 'narrowSymbol' | 'code' | 'name';

export class PriceUtils {
  static getFractionDigits(price: number) {
    return price % 1 === 0 ? 0 : 2;
  }

  static validateCurrencyCode(currencyCode: string) {
    const currencyCodeFormat = /^[a-zA-Z]{3}$/;
    return currencyCodeFormat.test(currencyCode);
  }

  static getFormattedCurrency({
    price,
    currencyDisplay = 'symbol',
    locale,
    currency,
    quantity = 1,
  }: {
    price: number;
    currencyDisplay?: CurrencyFormatName;
    locale: string;
    currency: string;
    quantity?: number;
  }) {
    const fractionDigits = PriceUtils.getFractionDigits(price);
    return this.validateCurrencyCode(currency)
      ? Intl.NumberFormat(locale, {
          style: 'currency',
          currency,
          currencyDisplay,
          maximumFractionDigits: fractionDigits,
          minimumFractionDigits: fractionDigits,
        }).format(Number(price * quantity))
      : '';
  }
}
