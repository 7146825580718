import {
  BulkResponse,
  BookingPolicy,
} from '@wix/ambassador-services-catalog-server/types';
import {
  ActionLabels,
  Schedule,
  Header as FormHeader,
  GetServiceResponse,
  ScheduleStatus,
  Rate,
} from '@wix/ambassador-services-catalog-server/http';
import { createFormView } from './serviceFormMapper';
import {
  mapBusinessResponseToBusinessInfo,
  BusinessInfo,
} from './businessInfoMapper';
import { Optional, DropdownOptions, DynamicPricingType } from '../types/types';
import {
  mapServiceLocations,
  mapServicePayment,
} from '@wix/bookings-uou-mappers';
import { mapServiceLocationToLocation, Location } from './locationMapper';
import {
  ServiceLocation,
  ServiceType,
  ServicePayment,
} from '@wix/bookings-uou-types';
import { FormView } from '@wix/forms-ui/types';
import { ServiceOptionsAndVariants } from '@wix/ambassador-bookings-catalog-v1-service-options-and-variants/types';
import { PriceUtils } from '../price/price';

type StaffMember = {
  id: string;
  name: string | undefined;
  label?: string;
  priceText?: string;
};

type CustomOption = {
  id: string;
  label?: string;
  priceText?: string;
};

export type Service = {
  id: string;
  name: string;
  rate: Rate;
  durationInMinutes: number;
  timeZone: string | undefined;
  staffMembers: StaffMember[];
  location: Location;
  isPendingApprovalFlow: boolean;
  isWaitingListFlow: boolean;
  type: ServiceType;
  formSchema: FormView;
  formHeader: FormHeader;
  videoConferenceProviderId?: string;
  includeConferenceOption?: boolean;
  actionLabels?: ActionLabels;
  locationDropdownOptions: DropdownOptions[];
  scheduleId: string;
  businessInfo: BusinessInfo;
  policy: BookingPolicy;
  isPremium: boolean;
  payment: ServicePayment;
  serviceVariants: ServiceOptionsAndVariants | undefined;
  serviceDynamicPricingType: DynamicPricingType;
  customOptions: CustomOption[];
};

export const mapCatalogServiceToService = async ({
  catalogData,
  serviceIndex,
  consultantApi,
  wixCodeApi,
  isDynamicPricingPerStaff,
}: {
  catalogData: Optional<BulkResponse>;
  serviceIndex: number;
  consultantApi: any;
  wixCodeApi: any;
  isDynamicPricingPerStaff: boolean;
}): Promise<Service> => {
  const activeSchedule: Schedule = getActiveSchedule(
    catalogData?.responseServices?.services?.[serviceIndex]!,
  );
  const selectedService =
    catalogData?.responseServices?.services?.[serviceIndex];
  const { applicableForCourse, applicableForGroups, applicableForIndividual } =
    catalogData?.responseBusiness?.activeFeatures!;
  const isPremium =
    applicableForCourse && applicableForGroups && applicableForIndividual;
  const serviceId = selectedService?.service?.id;
  const serviceType = getServiceType(activeSchedule);
  const formSchema = createFormView({ catalogData });
  const rate = activeSchedule.rate!;
  const formHeader = selectedService?.form?.header!;
  const isPendingApprovalFlow =
    selectedService?.service?.policy?.bookingsApprovalPolicy
      ?.isBusinessApprovalRequired || false;
  const timeZone = selectedService?.schedules?.[0].timeZone;
  const serviceLocations: ServiceLocation[] =
    mapServiceLocations(activeSchedule);
  const locationDropdownOptions = serviceLocations.map((location) => {
    return {
      label: location.businessLocation?.address?.formattedAddress!,
      value: location?.businessLocation?.id!,
    };
  });
  const businessInfo: BusinessInfo = mapBusinessResponseToBusinessInfo(
    catalogData?.responseBusiness!,
  );
  const payment: ServicePayment = mapServicePayment(selectedService!);
  const scheduleId = activeSchedule.id!;
  const location = mapServiceLocationToLocation(serviceLocations[0]);
  let serviceVariants, serviceDynamicPricingType;
  if (isDynamicPricingPerStaff && payment.paymentDetails.isVariedPricing) {
    serviceVariants = await consultantApi.getServiceVariants(serviceId);
    serviceDynamicPricingType =
      payment.paymentDetails.minPrice?.price !==
      payment.paymentDetails.maxPrice?.price
        ? serviceVariants.options.values[0].type
        : null;
  }
  const staffMembers = selectedService?.resources!.map((staffMember) => {
    let label = staffMember.name;
    let variant, priceText;
    if (
      isDynamicPricingPerStaff &&
      payment.paymentDetails.isVariedPricing &&
      serviceDynamicPricingType === DynamicPricingType.STAFF_MEMBER
    ) {
      variant = serviceVariants.variants.values.find(
        (aVariant) => aVariant.choices[0].staffMemberId === staffMember.id,
      );
      priceText = PriceUtils.getFormattedCurrency({
        price: Number(variant?.price.value),
        currency: String(variant?.price.currency),
        locale: String(wixCodeApi.site.regionalSettings),
      });
      label += ' ' + priceText;
    }
    return {
      id: staffMember.id!,
      name: staffMember.name,
      label,
      priceText,
    };
  });
  const customOptions = serviceVariants?.variants?.values.map((variant) => {
    const priceText = PriceUtils.getFormattedCurrency({
      price: Number(variant?.price.value),
      currency: String(variant?.price.currency),
      locale: String(wixCodeApi.site.regionalSettings),
    });
    return {
      id: variant.choices[0].custom,
      label: `${variant.choices[0].custom} ${priceText}`,
      priceText,
    };
  });
  return {
    id: serviceId!,
    name: selectedService?.service!.info!.name!,
    formHeader,
    rate,
    durationInMinutes:
      selectedService?.schedules?.[0]?.availability?.constraints
        ?.slotDurations?.[0]!,
    timeZone,
    type: serviceType,
    staffMembers: staffMembers!,
    location,
    isPendingApprovalFlow,
    isWaitingListFlow:
      selectedService?.service?.policy?.waitingListPolicy?.isEnabled!,
    videoConferenceProviderId: activeSchedule?.conferenceProvider?.providerId,
    includeConferenceOption: selectedService?.service?.includeConferenceOption,
    formSchema,
    actionLabels: selectedService?.form!.actionLabels!,
    locationDropdownOptions,
    scheduleId,
    businessInfo,
    policy: selectedService?.service?.policy!,
    isPremium: isPremium!,
    payment,
    serviceVariants,
    serviceDynamicPricingType,
    customOptions,
  };
};

export const getActiveSchedule = (service: GetServiceResponse): Schedule => {
  return (
    service?.schedules?.find(
      (schedule) => schedule.status === ScheduleStatus.CREATED,
    ) || service?.schedules?.[0]!
  );
};

export const getServiceType = (schedule: Schedule): ServiceType => {
  return (
    (schedule?.tags?.find(
      (tag: string) =>
        tag === ServiceType.COURSE ||
        tag === ServiceType.GROUP ||
        tag === ServiceType.INDIVIDUAL,
    ) as ServiceType) || ServiceType.INDIVIDUAL
  );
};
