import { WixOOISDKAdapter } from '@wix/bookings-adapter-ooi-wix-sdk';
import {
  ControllerParams,
  IWixAPI,
  FlowAPI,
  PlatformControllerFlowAPI,
} from '@wix/yoshi-flow-editor';
import { ServiceLocationType } from '@wix/bookings-uou-types';
import { getComponents } from '../../components/Consultants Widget/components';
import { SubmissionValue } from '@wix/forms-ui/types';
// import {createBlocksModel, FlowAPI, I$W} from '@wix/yoshi-flow-editor';

export interface CalendarApiInitParams {
  wixSdkAdapter: WixOOISDKAdapter;
  reportError: ControllerParams['flowAPI']['reportError'];
  flowAPI: PlatformControllerFlowAPI;
}

export interface IWixBookingAPI extends IWixAPI {
  [x: string]: any;
  bookings?: any;
}

export enum FilterTypes {
  LOCATION = 'LOCATION',
  STAFF_MEMBER = 'STAFF_MEMBER',
}

export enum DynamicPricingType {
  STAFF_MEMBER = 'STAFF_MEMBER',
  CUSTOM = 'CUSTOM',
}

export type TFunction = FlowAPI['translations']['t'];

export type Components = ReturnType<typeof getComponents>;

export type FormSubmission = {
  [k: string]: SubmissionValue;
};

export type FilterOptions = {
  [key in keyof typeof FilterTypes]: string[];
};

export type Optional<T> = T | undefined;

export type LocalDateTimeRange = {
  fromAsLocalDateTime: string;
  toAsLocalDateTime: string;
};

export type AvailabilityOptions = {
  startDateTime?: Date;
  endDateTime?: Date;
};

export enum SlotsAvailability {
  ALL,
  ONLY_AVAILABLE,
}

export type PlatformServerError = {
  details: any;
  message: any;
};

export type FormErrors =
  | EmptyStateType
  | GenericErrorType
  | CreateBookingErrorType
  | CouponsErrorType;

export enum EmptyStateType {
  SERVICE_NOT_FOUND = 'SERVICE_NOT_FOUND',
  SERVER_ERROR = 'SERVER_ERROR',
  GET_BOOKING_DETAILS_ERROR = 'GET_BOOKING_DETAILS_ERROR',
  GET_BOOKING_DETAILS_ACCESS_DENIED = 'GET_BOOKING_DETAILS_ACCESS_DENIED',
  SESSION_CAPACITY_EXCEEDED = 'SESSION_CAPACITY_EXCEEDED',
  SCHEDULE_CAPACITY_EXCEEDED = 'SCHEDULE_CAPACITY_EXCEEDED',
  SLOT_NOT_AVAILABLE = 'SLOT_NOT_AVAILABLE',
  BOOKING_POLICY_VIOLATION = 'BOOKING_POLICY_VIOLATION',
  CANNOT_FETCH_ECOM_ROLLOUT_STATUS = 'CANNOT_FETCH_ECOM_ROLLOUT_STATUS',
}

export enum GenericErrorType {
  EXCEEDED_ALLOWED_MAX_NUMBER_OF_PARTICIPANTS = 'EXCEEDED_ALLOWED_MAX_NUMBER_OF_PARTICIPANTS',
  GENERIC_PRICING_PLAN_ERROR = 'GENERIC_PRICING_PLAN_ERROR',
  GENERIC_MEMBER_DETAILS_ERROR = 'GENERIC_MEMBER_DETAILS_ERROR',
  GENERIC_BOOK_ERROR = 'GENERIC_BOOK_ERROR',
  BOOKINGS_SYSTEM_ERROR = 'BOOKINGS_SYSTEM_ERROR',
  GENERAL_ERROR = 'GENERAL_ERROR',
}

export enum CreateBookingErrorType {
  SESSION_CAPACITY_EXCEEDED = 'SESSION_CAPACITY_EXCEEDED',
  SCHEDULE_CAPACITY_EXCEEDED = 'SCHEDULE_CAPACITY_EXCEEDED',
  SLOT_NOT_AVAILABLE = 'SLOT_NOT_AVAILABLE',
  EXCEEDED_ALLOWED_MAX_NUMBER_OF_PARTICIPANTS = 'EXCEEDED_ALLOWED_MAX_NUMBER_OF_PARTICIPANTS',
  BOOKING_POLICY_VIOLATION = 'BOOKING_POLICY_VIOLATION',
}

export enum CouponsErrorType {
  COUPON_HAS_EXPIRED = 'ERROR_COUPON_HAS_EXPIRED',
  COUPON_USAGE_EXCEEDED = 'ERROR_COUPON_USAGE_EXCEEDED',
  COUPON_SERVICE_UNAVAILABLE = 'ERROR_COUPON_SERVICE_UNAVAILABLE',
  COUPON_DOES_NOT_EXIST = 'ERROR_COUPON_DOES_NOT_EXIST',
  NOT_VALID_FOR_SELECTED_SERVICE = 'ERROR_INVALID_PRODUCTS',
  GENERAL_SERVER_ERROR = 'ERROR_COUPON_GENERAL',
  COUPON_LIMIT_PER_CUSTOMER_EXCEEDED = 'ERROR_COUPON_LIMIT_PER_CUSTOMER_EXCEEDED',
}

export enum BookingsErrorCodes {
  BOOKING_POLICY_VIOLATION = '3',
  SLOT_NOT_AVAILABLE = '14',
  CANT_BOOK_CANCELED_SESSION = '11',
  SESSION_TIME_MISMATCH = '20',
  EXCEEDED_ALLOWED_MAX_NUMBER_OF_PARTICIPANTS = '4',
  SESSION_CAPACITY_EXCEEDED = '9',
}

export enum AvailabilityErrorCodes {
  BOOKINGS_SYSTEM_ERROR = '500',
}

export enum BookErrorType {
  SLOT_NOT_AVAILABLE = 'SLOT_NOT_AVAILABLE',
  SESSION_CAPACITY_EXCEEDED = 'SESSION_CAPACITY_EXCEEDED',
  CANT_BOOK_CANCELED_SESSION = 'CANT_BOOK_CANCELED_SESSION',
  SESSION_TIME_MISMATCH = 'SESSION_TIME_MISMATCH',
  BOOKING_POLICY_VIOLATION = 'BOOKING_POLICY_VIOLATION',
}

export type CheckoutOptions = {
  paymentType: string;
};

export type DropdownOptions = {
  label: string;
  value: string;
};

export type AddError = (errorType: CalendarErrors) => void;

export enum CalendarErrors {
  RESCHEDULE_SERVER_ERROR = 'reschedule_server_error',
  AVAILABLE_SLOTS_SERVER_ERROR = 'available_slots_server_error',
  NEXT_AVAILABLE_DATE_SERVER_ERROR = 'next_available_date_server_error',
  NO_SELECTED_LOCATION_ERROR = 'selected_slot_validation_no_selected_location',
  NO_SELECTED_DURATION_ERROR = 'selected_slot_validation_no_selected_duration',
  NO_SELECTED_STAFF_MEMBER_ERROR = 'selected_slot_validation_no_selected_staff_member',
  NO_TIME_SELECTED_ERROR = 'selected_slot_validation_no_time_selected_error',
  NO_VALID_PRICING_PLAN_IN_RESCHEDULE_FLOW_ERROR = 'no_valid_pricing_plan_in_reschedule_flow_error',
  NO_VALID_PRICING_PLAN_WARNING = 'no_valid_pricing_plan_warning',
  NO_NEXT_AVAILABLE_DATE_WARNING = 'no_next_available_date',
}

export type WidgetData = {
  serviceId: string;
};

export interface Slot {
  serviceId: string;
  _id: string;
  startDateTime: Date;
  endDateTime: Date;
  capacity: number;
  remainingSpots: number;
  staffMemberId: string;
  bookable: boolean;
  constraints: any;
  location?: SlotLocation;
}

export interface SlotLocation {
  type: ServiceLocationType;
  locationText?: string;
  businessLocation?: {
    id?: string;
    name?: string;
    description?: string;
    address?: {
      formatted?: string;
      location?: {
        latitude?: number;
        longitude?: number;
      };
      streetAddress?: {
        name?: string;
        number?: string;
      };
      city?: string;
      subdivision?: string;
      country?: string;
      postalCode?: string;
    };
  };
}

export interface GetServiceAvailabilityResponse {
  slots: Slot[];
}

export interface BookingInfo {
  slot: Slot;
  formFields: FormField[];
}

export interface FormField {
  _id: string;
  value: string;
}

export interface CheckoutBookingResult {
  bookingId: string;
  status: string;
}

export const CheckoutBookingStatus = {
  CONFIRMED: 'Confirmed',
  TERMINATED: 'Terminated',
  PENDING_APPROVAL: 'Pending Approval',
};

export const WIX_PAY_SUCCESSFUL_STATUS = ['Pending', 'Successful', 'Offline'];
