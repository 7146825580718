import model from './model';
import { getComponents } from './components';

export default model.createController(({ $w, $widget, flowAPI }) => {
  let components: ReturnType<typeof getComponents>;

  const init = () => {
    components.label.text = flowAPI.translations.t(
      'app.widget.header.video-conference',
    );
  };

  return {
    pageReady: async () => {
      components = getComponents($w);
      init();
      $widget.fireEvent('widgetLoaded', {});
    },
    exports: {},
  };
});
