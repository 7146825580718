// import { getComponents } from '../../components';
import { FlowAPI } from '@wix/yoshi-flow-editor';
import { ConsultantsWidgetComponentIds as ComponentIds } from '../../config/constants';

export const showRequiredMsg = ({
  component,
  translations,
  translationKey,
}: {
  component: any;
  translations: FlowAPI['translations'];
  translationKey?: string;
}) => {
  const { t } = translations;
  let textComp;
  const compId = `#${component.id}`;
  if (
    compId === ComponentIds.locationDropdownErrorMessge ||
    compId === ComponentIds.datePickerErrorMessage ||
    compId === ComponentIds.timeDropdownErrorMessage ||
    compId === ComponentIds.staffDropdownErrorMessage
  ) {
    textComp = component.children[0].children.find((comp) => comp.text);
  } else {
    textComp = component.children.find((comp) => comp.text);
  }
  if (translationKey) {
    textComp.text = t(translationKey);
  }
  component.expand();
};
