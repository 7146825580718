export interface DurationMapperOptions {
  hourUnit: string;
  minuteUnit: string;
  hourAriaUnit?: string;
  minuteAriaUnit?: string;
  durationVaries?: string;
  coursePassedText?: string;
  courseInFutureText?: string;
}

const formattedHours = (hours: number, hourUnit: string) => {
  const hourText = `${hours} ${hourUnit}`;
  const hoursAriaLabelFormatterKey = 'duration.units.aria-hours';
  const isHourTextForAriaLabel = hourUnit === hoursAriaLabelFormatterKey;
  return hours === 1 && isHourTextForAriaLabel
    ? hourText.slice(0, -1)
    : hourText;
};

export const formatDurationFromMinutes = ({
  durationInMinutes,
  hourUnit,
  minuteUnit,
}) => {
  const hours = Math.floor(durationInMinutes / 60);
  const minutes = durationInMinutes % 60;
  const hourText = formattedHours(hours, hourUnit);
  const minuteText = `${minutes} ${minuteUnit}`;
  return hours ? hourText + (minutes ? ` ${minuteText}` : '') : minuteText;
};
