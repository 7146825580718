import {
  createBlocksModel,
  // WidgetPropertyType,
} from '@wix/yoshi-flow-editor/blocks';
import { WidgetData } from '../../utils/types/types';
// ecom site serviceId: 69a2f271-eb3a-41a1-9521-fca248d5c92c
// non ecom site servcieId: 3ebf409c-c2cf-4eef-a943-4dcb45421ed6
const DEFAULT_DATA = {
  serviceId: '',
} as WidgetData;

/*
  Place where blocks model is initialized.
  Blocks model allows you to define your Widget Public APIs in a single place.
  It will automatically provide `getExports` for Editor Script and provide typings based on the model for Viewer Script (via model.createController).
*/
export default createBlocksModel({
  widgetName: 'Consultants Widget',
  props: {
    // Here you can define your widget props
    data: {
      type: {} as any,
      title: 'Consultants Widget Props',
      defaultValue: DEFAULT_DATA,
      description: 'Property for consultants widget',
    },
  },
  methods: {
    // Here you can define your widget public props
  },
  events: {
    // Here you can define events your widget will subscribe to
    widgetLoaded: {
      description: 'Fired when Widget loaded',
    },
  },
});
