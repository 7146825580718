// import { getComponents } from '../components';
import { AvailabilityStateTypes } from '../config/constants';
import { Components } from '../../../utils/types/types';

export const setView = ({ components }: { components: Components }) => {
  hideNotifications(components);
  components.multiStateAvailability.changeState(
    AvailabilityStateTypes.WITH_AVAILABILITY,
  );

  components.slotsDropdown.options = [];
  components.paymentOptionsDropdown.collapse();
};

const hideNotifications = (components) => {
  collapseComponent(components.locationDropdownErrorMessge);
  collapseComponent(components.datePickerErrorMessage);
  collapseComponent(components.timeDropdownErrorMessage);
  collapseComponent(components.staffDropdownErrorMessage);
  collapseComponent(components.errorToast);
  collapseComponent(components.shortInputErrorMessage);
  collapseComponent(components.longInputErrorMessage);
  collapseComponent(components.phoneInputErrorMessage);
  collapseComponent(components.checkboxErrorMessage);
  collapseComponent(components.topErrorNotification);
  collapseComponent(components.formErrorNotification);
  collapseComponent(components.successNotification);
  collapseComponent(components.customVariantsDropdown);
  collapseComponent(components.customVariantsDropdownErrorMessage);
};

const collapseComponent = (component) => {
  component.collapse();
};
