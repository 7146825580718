import { PriceUtils } from '../../../../../utils/price/price';
import { formatDurationFromMinutes } from '../../../../../utils/mappers/durationMapper';
import { options } from '../../../config/constants';
import {
  IWixBookingAPI,
  Components,
  TFunction,
} from '../../../../../utils/types/types';
import { Service } from '../../../../../utils/mappers/serviceMapper';

export const setHeaderSection = (
  components: Components,
  service: Service,
  wixCodeApi: IWixBookingAPI,
  isDynamicPricingPerStaff: boolean,
  t: TFunction,
) => {
  const {
    staffMembers,
    name,
    rate,
    durationInMinutes,
    locationDropdownOptions,
    payment,
  } = service;
  components.serviceTitle.text = name;
  if (rate) {
    if (rate.labeledPriceOptions?.general) {
      components.servicePrice.text = PriceUtils.getFormattedCurrency({
        price: Number(rate.labeledPriceOptions?.general.amount),
        currency: String(rate.labeledPriceOptions?.general.currency),
        locale: String(wixCodeApi.site.regionalSettings),
      });
    } else if (
      isDynamicPricingPerStaff &&
      payment.paymentDetails.isVariedPricing
    ) {
      const priceText = PriceUtils.getFormattedCurrency({
        price: Number(payment.paymentDetails.minPrice?.price),
        currency: String(payment.paymentDetails.minPrice?.currency),
        locale: String(wixCodeApi.site.regionalSettings),
      });
      const prefix =
        payment.paymentDetails.minPrice?.price ===
        payment.paymentDetails.maxPrice?.price
          ? ''
          : t('app.widget.header.price-prefix');
      components.servicePrice.text = `${prefix}${priceText}`;
    } else {
      components.servicePrice.text = rate.priceText;
    }
  } else {
    components.servicePrice.collapse();
    components.priceDivider.collapse();
  }
  components.serviceDuration.text = formatDurationFromMinutes({
    hourUnit: options.hourUnit,
    minuteUnit: options.minuteUnit,
    durationInMinutes,
  });
  if (staffMembers.length > 1) {
    components.staffName.collapse();
    components.staffNameDivider.collapse();
  } else {
    components.staffName.text = staffMembers[0].name;
  }
  if (locationDropdownOptions.length > 1) {
    components.serviceLocation.collapse();
  } else {
    components.serviceLocation.text =
      service.location.locationType === 'CUSTOM'
        ? t('app.widget.header.service-location.client-location')
        : service.location.address;
  }
  if (service.videoConferenceProviderId || service.includeConferenceOption) {
    const onlineBadgeLabel =
      components.onlineBadge.children[0].children[0].children.find(
        (comp) => comp.text,
      );
    onlineBadgeLabel.text = t('app.widget.header.video-conference');
    components.onlineBadge.expand();
  } else {
    components.onlineBadge.collapse();
  }
};
