import { formatTimezone } from '@wix/bookings-uou-mappers';
import {
  dateToEndOfDayDate,
  getEnavledDatesForAmbassadorAvailability,
  getLocalDateTimeStartOfDay,
  getSlotsViewForSelectedDates,
  getTimestempFromDateTime,
} from '../../../../../utils/dateAndTime/dateAndTime';
import { setHeaderSection } from '../header/header';
import {
  FlowAPI,
  PlatformControllerFlowAPI,
  I$W,
  HttpClient,
} from '@wix/yoshi-flow-editor';
import {
  IWixBookingAPI,
  Components,
  LocalDateTimeRange,
  FormField,
  FormSubmission,
  DynamicPricingType,
} from '../../../../../utils/types/types';
import { SlotAvailability } from '@wix/ambassador-bookings-availability-v1-slot-availability/types';
import { Service } from '../../../../../utils/mappers/serviceMapper';
import { showNotificationSuccess } from '../../notifications/success';
import { mapFormSubmission } from '../../../../../utils/mappers/formSubmission';
import { PaymentType } from '@wix/bookings-uou-types';
import {
  CreateCheckoutResponse,
  CreateOrderResponse,
} from '@wix/ambassador-checkout/types';
import { WixOOISDKAdapter } from '@wix/bookings-adapter-ooi-wix-sdk';
import { paymentOptions, ReferalInfoTypes } from '../../../config/constants';
import { getActionLabel } from '../../../../../utils/mappers/paymentMapper';
import {
  validateAvailabilitySection,
  AvailabilityValidation,
  validateDatePicker,
} from '../../../../../utils/availability/availabilityValidation';
import { showRequiredMsg } from '../../notifications/requiredMessage';
import { validateFormSection } from '../../../../../utils/form/formValidation';
import { ServiceType } from '@wix/bookings-uou-types/dist/src';
import { PriceUtils } from '../../../../../utils/price/price';
import { ParticipantChoices } from '@wix/ambassador-bookings-gateway/types';
import {
  APP_NAME,
  SelectionTypes,
} from '../../../../../services/BiLogger/constants';
import {
  reportQuickBookSelectDateTime,
  reportBookItClick,
} from '../../../../../services/BiLogger/BiLogger';
import { ConsultantApi } from '../../../../../api/consultantApi';

export const DEFAULT_VALUE = 'DEFAULT_VALUE';

export const setAvailabilitySection = async ({
  components,
  service,
  wixCodeApi,
  translations,
  availabilityEntries,
  wixBookingsCodeAPI,
  consultantApi,
  fieldsValue,
  flowAPI,
  formSubmission,
  wixSdkAdapter,
  isDynamicPricingPerStaff,
  isDynamicPricingCustom,
  biLogger,
  $w,
}: {
  components: Components;
  service: Service;
  wixCodeApi: IWixBookingAPI;
  translations: FlowAPI['translations'];
  availabilityEntries: SlotAvailability[] | undefined;
  wixBookingsCodeAPI: any;
  consultantApi: any;
  fieldsValue: FormField[];
  flowAPI: PlatformControllerFlowAPI;
  formSubmission: FormSubmission;
  wixSdkAdapter: WixOOISDKAdapter;
  isDynamicPricingPerStaff: boolean;
  isDynamicPricingCustom: boolean;
  biLogger: any;
  $w: I$W;
}) => {
  const { t } = translations;
  const { staffMembers, locationDropdownOptions, actionLabels } = service;

  setHeaderSection(
    components,
    service,
    wixCodeApi,
    isDynamicPricingPerStaff,
    t,
  );
  components.bookButton.label = getActionLabel({
    paymentType: service.payment.paymentDetails.paymentType,
    actionLabels,
    isPendingApprovalFlow: service.isPendingApprovalFlow,
    t,
  });
  let staffDropdownOptions, selectedTimeOption;
  if (staffMembers.length > 1) {
    staffDropdownOptions = mapStaffMembersList(staffMembers);
    setOptions(
      components.staffDropdown,
      t,
      'app.widget.staff-dropdown.placeholder',
      staffDropdownOptions,
    );
  } else {
    staffDropdownOptions = [];
    components.box12.collapse();
  }
  if (locationDropdownOptions.length > 1) {
    setOptions(
      components.locationDropdown,
      t,
      'app.widget.location-dropdown.placeholder',
      locationDropdownOptions,
    );
  } else {
    components.box10.collapse();
  }
  if (
    isDynamicPricingCustom &&
    isCustomVariant(service.serviceDynamicPricingType) &&
    service.payment.paymentDetails.minPrice?.price !==
      service.payment.paymentDetails.maxPrice?.price
  ) {
    const customVariantsOptions = mapCustomVariants(service.customOptions);
    const customName =
      service.serviceVariants?.options?.values?.[0].customData?.name!;
    components.customVariantsDropdown.label = customName;
    components.customVariantsDropdown.placeholder = flowAPI.translations.t(
      'app.widget.custom-variant-dropdown.placeholder',
      { customName },
    );
    components.customVariantsDropdown.options = customVariantsOptions;
    components.customVariantsDropdown.expand();
  }
  const formattedTimezone: string = formatTimezone({
    date: new Date(),
    locale: wixCodeApi.site.regionalSettings || 'en-us',
    timezone: service.timeZone!,
  });
  components.timezoneDropdown.options = [
    {
      label: formattedTimezone,
      value: formattedTimezone,
    },
  ];

  components.datePicker.resetValidityIndication();

  const handler = async (e) => {
    const selectedMonthvailability: SlotAvailability[] =
      e.options.operationResult;
    availabilityEntries = selectedMonthvailability!;
    let selectedLocation =
      components.locationDropdown.value === DEFAULT_VALUE
        ? null
        : components.locationDropdown.value;
    selectedLocation = components.box10.collapsed
      ? service.location.id || service.location.locationType
      : selectedLocation;
    if (selectedLocation) {
      components.datePicker.enabledDateRanges =
        await getEnavledDatesForAmbassadorAvailability({
          availabilityEntries,
          wixCodeApi,
          selectedLocationId: selectedLocation,
        });
      components.datePicker.resetValidityIndication();
    } else {
      components.datePicker.enabledDateRanges = [];
      showRequiredMsg({
        component: components.locationDropdownErrorMessge,
        translations,
        translationKey: 'app.booking-details.dropdowns.error.location.text',
      });
    }
  };
  const operation = async (e) => {
    const today = e.options.startDate;
    const nextMonth = e.options.endDate;
    const localDateTimeRange: LocalDateTimeRange = {
      fromAsLocalDateTime: getLocalDateTimeStartOfDay(today),
      toAsLocalDateTime: getTimestempFromDateTime(nextMonth, service.timeZone!),
    };
    const selectedMonthAvailability = await getAvailability(
      consultantApi,
      localDateTimeRange,
      service,
      components,
      true,
    );
    return selectedMonthAvailability;
  };
  const timeout = 60000;
  components.datePicker.onViewChange(handler, operation, timeout);

  components.datePicker.onChange(async (e) => {
    components.datePickerErrorMessage.collapse();
    resetSlotsDropdown(components);
    const selectedDate = e.target.value;
    const nextMonth = dateToEndOfDayDate({
      date: selectedDate,
      timeZone: service.timeZone!,
    });
    const localDateTimeRange: LocalDateTimeRange = {
      fromAsLocalDateTime: getLocalDateTimeStartOfDay(selectedDate),
      toAsLocalDateTime: getTimestempFromDateTime(nextMonth, service.timeZone!),
    };
    availabilityEntries = await getAvailability(
      consultantApi,
      localDateTimeRange,
      service,
      components,
      false,
    );
    reportQuickBookSelectDateTime(biLogger, {
      serviceId: service.id,
      is_over_editor: wixSdkAdapter.isEditorMode(),
      is_owner: wixSdkAdapter.isOwner(),
      businessId: wixSdkAdapter.getInstanceId(),
      availableSlots: JSON.stringify(availabilityEntries),
      selectedValue: e.target.value,
      selectionType: SelectionTypes.select_date,
    });

    components.slotsDropdown.options = getSlotsViewForSelectedDates(
      e.target.value,
      availabilityEntries,
      wixCodeApi,
      components.locationDropdown.value || service.location.id,
    );
  });

  components.datePicker.onClick(() => {
    reportQuickBookSelectDateTime(biLogger, {
      serviceId: service.id,
      is_over_editor: wixSdkAdapter.isEditorMode(),
      is_owner: wixSdkAdapter.isOwner(),
      businessId: wixSdkAdapter.getInstanceId(),
      selectionType: SelectionTypes.dropdown_date,
    });
  });

  components.slotsDropdown.onClick(() => {
    const isDateValid = validateDatePicker(components.datePicker.value);
    if (!isDateValid) {
      showRequiredMsg({
        component: components.datePickerErrorMessage,
        translations,
        translationKey: 'app.booking-details.dropdowns.error.datePicker.text',
      });
    } else {
      reportQuickBookSelectDateTime(biLogger, {
        serviceId: service.id,
        is_over_editor: wixSdkAdapter.isEditorMode(),
        is_owner: wixSdkAdapter.isOwner(),
        businessId: wixSdkAdapter.getInstanceId(),
        availableSlots: JSON.stringify(availabilityEntries),
        selectionType: SelectionTypes.dropdown_time,
      });
    }
  });

  components.slotsDropdown.onChange((e) => {
    const { payment } = service;
    components.timeDropdownErrorMessage.collapse();
    if (staffMembers.length > 1) {
      components.staffName.collapse();
      components.staffNameDivider.collapse();
      if (
        isDynamicPricingPerStaff &&
        service.payment.paymentDetails.isVariedPricing
      ) {
        const priceText = PriceUtils.getFormattedCurrency({
          price: Number(payment.paymentDetails.minPrice?.price),
          currency: String(payment.paymentDetails.minPrice?.currency),
          locale: String(wixCodeApi.site.regionalSettings),
        });
        const prefix =
          payment.paymentDetails.minPrice?.price ===
          payment.paymentDetails.maxPrice?.price
            ? ''
            : t('app.widget.header.price-prefix');
        components.servicePrice.text = `${prefix}${priceText}`;
      }
      if (
        isDynamicPricingCustom &&
        isCustomVariant(service.serviceDynamicPricingType)
      ) {
        components.customVariantsDropdown.value = null;
        components.customVariantsDropdown.resetValidityIndication();
      }
    }
    const selectedSlotsIndexList = JSON.parse(e.target.value);
    const options = mapStaffMembersList(staffMembers);
    let newOptions: any[] = [];
    const staffList: string[] = [];
    for (const slotIndex of selectedSlotsIndexList) {
      staffList.push(availabilityEntries?.[slotIndex].slot?.resource?.id!);
    }
    for (const staff of options) {
      const index = !staffList.includes(staff.value)
        ? options.indexOf(staff)
        : -1;
      if (index > -1) {
        options.splice(index, 1);
        newOptions = [...newOptions, ...options];
      }
    }
    if (newOptions.length === 0) {
      newOptions = options;
    }
    setOptions(
      components.staffDropdown,
      t,
      'app.widget.staff-dropdown.placeholder',
      newOptions,
    );
    selectedTimeOption = e.target.options.find(
      (option) => option.value === e.target.value,
    );
    reportQuickBookSelectDateTime(biLogger, {
      serviceId: service.id,
      is_over_editor: wixSdkAdapter.isEditorMode(),
      is_owner: wixSdkAdapter.isOwner(),
      businessId: wixSdkAdapter.getInstanceId(),
      availableSlots: JSON.stringify(availabilityEntries),
      selectedValue: selectedTimeOption.label,
      selectionType: SelectionTypes.select_time,
    });
  });

  components.staffDropdown.onChange((e) => {
    components.staffDropdownErrorMessage.collapse();
    if (staffMembers.length > 1) {
      const [staff] = staffMembers.filter(
        (staffObj) => staffObj.id === e.target.value,
      );
      if (staff.id === DEFAULT_VALUE) {
        components.staffName.collapse();
        components.staffNameDivider.collapse();
      } else {
        components.staffName.text = staff.name;
        components.staffName.expand();
        components.staffNameDivider.expand();
      }
      if (service.payment.paymentDetails.isVariedPricing) {
        if (
          !isCustomVariant(service.serviceDynamicPricingType) &&
          service.serviceDynamicPricingType !== null
        ) {
          components.servicePrice.text = staff.priceText;
        }
      }
    }
  });

  components.locationDropdown.onChange((e) => {
    components.locationDropdownErrorMessge.collapse();
    resetDatePicker(components);
    resetSlotsDropdown(components);
    if (locationDropdownOptions.length > 1) {
      const [location] = locationDropdownOptions.filter(
        (locationObj) => locationObj.value === e.target.value,
      );
      if (location.value === DEFAULT_VALUE) {
        components.serviceLocation.collapse();
      } else {
        components.serviceLocation.text = location.label;
        components.serviceLocation.expand();
      }
    }
  });

  components.customVariantsDropdown.onChange((e) => {
    components.customVariantsDropdownErrorMessage.collapse();
    const selectedVariant = service.customOptions.find(
      (option) => option.id === e.target.value,
    );
    components.servicePrice.text = selectedVariant?.priceText;
  });

  components.bookButton.onClick(async () => {
    components.formErrorNotification.collapse();
    reportBookItClick(biLogger, {
      service_id: service.id,
      is_over_editor: wixSdkAdapter.isEditorMode(),
      is_owner: wixSdkAdapter.isOwner(),
      businessId: wixSdkAdapter.getInstanceId(),
      referralInfo: APP_NAME,
    });
    const { isPreview } = flowAPI.environment;
    if (!service.isPremium && isPreview) {
      await wixSdkAdapter.openPreviewPremiumModal(
        ServiceType.INDIVIDUAL,
        ReferalInfoTypes.APPOINTMENT_FORM,
      );
    } else if (!service.isPremium) {
      await wixSdkAdapter.openUoUPremiumModal(
        ServiceType.INDIVIDUAL,
        ReferalInfoTypes.APPOINTMENT_FORM,
      );
    } else {
      const availabilitySectionValidation: AvailabilityValidation =
      validateAvailabilitySection(components, service);
      if (availabilitySectionValidation.allFeildesValid) {
        const formValidation = validateFormSection(formSubmission, service);
        if (Object.keys(formValidation).length === 0) {
          let slot;
          const selectedSlotsIndexList = JSON.parse(
            components.slotsDropdown.value,
          );
          if (selectedSlotsIndexList.length > 1) {
            for (const slotIndex of selectedSlotsIndexList) {
              const aSlot = availabilityEntries?.[slotIndex];
              if (
                components.staffDropdown.value === aSlot?.slot?.resource?.id
              ) {
                slot = aSlot?.slot;
              }
            }
          } else {
            slot = availabilityEntries?.[selectedSlotsIndexList[0]].slot;
          }
          const { paymentOptionsDropdown } = components;

          if (!isPreview) {
            const paymentType = !components.paymentOptionsDropdown.collapsed
              ? paymentOptionsDropdown.value
              : service.payment.paymentDetails.paymentType ===
                PaymentType.ONLINE
              ? PaymentType.ONLINE
              : PaymentType.OFFLINE;
            const {
              contactDetails,
              additionalFields,
              totalParticipants,
              sendSmsReminder,
            } = mapFormSubmission(formSubmission, service, wixCodeApi);
            let participantsChoices: ParticipantChoices | undefined;
            if (
              isDynamicPricingCustom &&
              isCustomVariant(service.serviceDynamicPricingType)
            ) {
              const selectedVariant = getVariantByCustom(
                service,
                components.customVariantsDropdown.value,
              );
              participantsChoices =
                mapVariantToParticipentsChoices(selectedVariant);
            }
            const createCheckoutResponse = await consultantApi.checkoutBooking({
              httpClient: flowAPI.httpClient,
              slot: slot!,
              contactDetails,
              additionalFields,
              totalParticipants,
              sendSmsReminder,
              selectedPaymentOption: paymentType,
              ...(participantsChoices ? { participantsChoices } : {}),
            });
            if (isOnlineFlow(createCheckoutResponse)) {
              return wixSdkAdapter.navigateToEcomCheckoutPage({
                checkoutId: createCheckoutResponse!.checkout!.id!,
              });
            } else {
              if (service.isPendingApprovalFlow) {
                components.successNotification.children[0].children[0].children[0].text =
                  t('app.booking.succeeded.request-to-book');
              }
              showNotificationSuccess(components);
              resetAllFields(components);
            }
          } else {
            await wixSdkAdapter.openEcomCheckoutPreviewModal();
          }
        } else {
          components.formRepeater.forEachItem(($item, itemData) => {
            if (itemData._id === formValidation[itemData._id]) {
              const [formErrorNotificationText] =
                components.formErrorNotification.children[0].children[0].children.filter(
                  (child) => child.text,
                );
              formErrorNotificationText.text = flowAPI.translations.t(
                'app.form-inputs.validation-errors.required-fields',
              );
              components.formErrorNotification.expand();
              components.formErrorNotification.scrollTo();
            }
          });
        }
      } else {
        availabilitySectionValidation.requiredFeilds.forEach(
          (requiredFeildId) => {
            showRequiredMsg({
              component: $w(requiredFeildId),
              translations,
            });
          },
        );
        components.serviceTitle.scrollTo();
      }
    }
  });
};

const isOnlineFlow = (
  checkoutResponse: CreateCheckoutResponse | CreateOrderResponse,
): checkoutResponse is CreateCheckoutResponse => {
  return (checkoutResponse as CreateCheckoutResponse).checkout !== undefined;
};

const setOptions = (component, t, translationKey, options) => {
  component.options = options;
  component.value = null;
  component.resetValidityIndication();
};

const getAvailability = async (
  consultantApi,
  localDateTimeRange,
  service,
  components,
  shouldLimitPerDay,
) => {
  const staffMembersIds =
    service?.staffMembers?.length > 1
      ? service?.staffMembers?.map((staffMember) => staffMember.id)
      : [service?.staffMembers?.[0].id!];
  const locations =
    service.locationDropdownOptions.length > 1
      ? [components.locationDropdown.value]
      : [];
  return (
    await consultantApi.getDateAvailability(localDateTimeRange, {
      selectedTimezone: service?.timeZone || '',
      filterOptions: {
        LOCATION: locations,
        STAFF_MEMBER: staffMembersIds,
      },
      selectedService: service,
      shouldLimitPerDay,
    })
  ).availabilityEntries;
};

const resetAllFields = (components) => {
  resetSlotsDropdown(components);
  resetDatePicker(components);
  components.staffDropdown.value = null;
  components.staffDropdown.resetValidityIndication();
  components.locationDropdown.value = null;
  components.locationDropdown.resetValidityIndication();
  components.longInput.value = null;
  components.longInput.resetValidityIndication();
  components.shortInput.value = null;
  components.shortInput.resetValidityIndication();
  components.paymentOptionsDropdown.value =
    // eslint-disable-next-line no-self-assign
    components.paymentOptionsDropdown.value;
  components.phoneCodeDropdown.value = null;
  components.phoneCodeDropdown.resetValidityIndication();
  components.phoneInput.value = null;
  components.phoneInput.resetValidityIndication();
  components.formCheckbox.checked = false;
  components.formCheckbox.resetValidityIndication();
};

const resetSlotsDropdown = (components) => {
  components.slotsDropdown.options = [];
  components.slotsDropdown.value = null;
  components.slotsDropdown.resetValidityIndication();
};

const resetDatePicker = (components) => {
  components.datePicker.value = null;
  components.datePicker.resetValidityIndication();
};

const mapStaffMembersList = (staffMembers) => {
  return staffMembers.map((staffMember) => {
    return {
      label: staffMember.label,
      value: staffMember.id,
    };
  });
};

const mapCustomVariants = (customVariants) => {
  return customVariants.map((variant) => {
    return {
      label: variant.label,
      value: variant.id,
    };
  });
};

const getVariantByCustom = (service, custom) => {
  return service.serviceVariants?.variants?.values?.find(
    (variant) => variant.choices?.[0].custom === custom,
  );
};

const isCustomVariant = (
  serviceDynamicPricingType: DynamicPricingType,
): boolean => {
  return serviceDynamicPricingType === DynamicPricingType.CUSTOM;
};

const mapVariantToParticipentsChoices = (selectedVariant) => {
  return {
    serviceChoices: [
      {
        choices: [selectedVariant?.choices?.[0]!],
        numberOfParticipants: 1,
      },
    ],
  };
};
