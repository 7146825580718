import { PaymentOptions } from '@wix/ambassador-services-catalog-server/http';
import { PaymentType } from '@wix/bookings-uou-types';
import { Service } from './serviceMapper';

export function mapPaymentOptionsToPaymentType(
  paymentOptions: PaymentOptions,
): PaymentType {
  if (paymentOptions.wixPayOnline && !paymentOptions.wixPayInPerson) {
    return PaymentType.ONLINE;
  }
  if (!paymentOptions.wixPayOnline && paymentOptions.wixPayInPerson) {
    return PaymentType.OFFLINE;
  }
  return PaymentType.BOTH;
}

export const getActionLabel = ({
  paymentType,
  actionLabels,
  isPendingApprovalFlow,
  t,
}: {
  paymentType: PaymentType;
  actionLabels: Service['actionLabels'];
  isPendingApprovalFlow: boolean;
  t: Function;
}): string => {
  if (isPendingApprovalFlow) {
    return t('app.booking-details.summary.cta.request-to-book.label');
  }
  if (paymentType === PaymentType.OFFLINE) {
    return t('app.booking-details.summary.cta.book.label');
  }
  return t('app.booking-details.summary.cta.pay-now.label');
};
