import {
  BulkRequest,
  BulkResponse,
  ServicesCatalogServer,
} from '@wix/ambassador-services-catalog-server/http';
import type { IHttpClient } from '@wix/fe-essentials/http-client';
import { queryAvailability } from '@wix/ambassador-bookings-availability-v1-slot-availability/http';
import {
  QueryAvailabilityRequest,
  QueryAvailabilityResponse,
} from '@wix/ambassador-bookings-availability-v1-slot-availability/types';

import { queryServiceOptionsAndVariants } from '@wix/ambassador-bookings-catalog-v1-service-options-and-variants/http';
import { ServiceOptionsAndVariants } from '@wix/ambassador-bookings-catalog-v1-service-options-and-variants/types';
import {
  ControllerParams,
  PlatformControllerFlowAPI,
} from '@wix/yoshi-flow-editor';
import {
  WixOOISDKAdapter,
  BOOKINGS_APP_DEF_ID,
} from '@wix/bookings-adapter-ooi-wix-sdk';
import { getTodayLocalDateTimeStartOfDay } from '../utils/dateAndTime/dateAndTime';
import { Service } from '../utils/mappers/serviceMapper';
import { mapCheckoutBookingError } from '../utils/errors/errors';
import {
  CalendarApiInitParams,
  Optional,
  EmptyStateType,
  LocalDateTimeRange,
  FilterOptions,
} from '../utils/types/types';
import {
  ApiChannelType,
  Checkout as EcomCheckoutServer,
  CreateCheckoutResponse,
  CreateCheckoutRequest,
  CreateOrderResponse,
} from '@wix/ambassador-checkout/http';
import { createBooking } from '@wix/ambassador-bookings-v2-booking/http';
import {
  V2Booking,
  BookingSourcePlatform,
  BookingSourceActor,
  V2BookedSlot,
  V2ContactDetails,
  CreateBookingRequest,
  CreateBookingResponse,
} from '@wix/ambassador-bookings-v2-booking/types';
import { getSiteRolloutStatus } from '@wix/ambassador-bookings-v1-site-rollout-status/http';

const CATALOG_SERVER_URL = '_api/services-catalog';
const ECOM_CHECKOUT_URL = 'ecom';
export class ConsultantApi {
  private readonly flowAPI: PlatformControllerFlowAPI | any;
  private authorization: string;
  private wixSdkAdapter: WixOOISDKAdapter;
  private readonly reportError: ControllerParams['flowAPI']['reportError'];
  private catalogServer: ReturnType<typeof ServicesCatalogServer>;
  private ecomCheckoutServer: ReturnType<typeof EcomCheckoutServer>;

  constructor({ wixSdkAdapter, reportError, flowAPI }: CalendarApiInitParams) {
    this.flowAPI = flowAPI;
    this.wixSdkAdapter = wixSdkAdapter;
    this.reportError = reportError;
    this.authorization = this.wixSdkAdapter.getInstance();
    const baseUrl = this.wixSdkAdapter.getServerBaseUrl();
    this.catalogServer = ServicesCatalogServer(
      `${baseUrl}${CATALOG_SERVER_URL}`,
    );
    this.ecomCheckoutServer = EcomCheckoutServer(
      `${baseUrl}${ECOM_CHECKOUT_URL}`,
    );
  }

  async getCatalogData({
    onError,
    serviceId = null,
  }: {
    onError: (type: EmptyStateType) => void;
    serviceId?: string | null;
  }): Promise<Optional<BulkResponse>> {
    const filter = serviceId
      ? JSON.stringify({
          'service.id': { $eq: serviceId },
        })
      : JSON.stringify({
          'schedules.tags': { $eq: 'INDIVIDUAL' },
          'service.paymentOptions.wixPaidPlan': { $eq: false },
        });
    const servicesCatalogService = this.catalogServer.Bulk();
    const bulkRequest: BulkRequest = {
      requestServices: {
        includeDeleted: false,
        query: {
          fieldsets: [],
          filter,
          paging: {
            limit: 500,
          },
          fields: [],
          sort: [],
        },
      },
      requestBusiness: {
        suppressNotFoundError: false,
      },
    };

    try {
      const catalogData: BulkResponse = await servicesCatalogService({
        Authorization: this.authorization,
      }).get(bulkRequest);
      return catalogData;
    } catch (e: any) {
      this.reportError(e);
      onError(EmptyStateType.SERVER_ERROR);
    }
  }

  async getDateAvailability(
    { fromAsLocalDateTime, toAsLocalDateTime }: LocalDateTimeRange,
    {
      selectedTimezone,
      filterOptions,
      selectedService,
      shouldLimitPerDay = false,
    }: {
      selectedTimezone: string;
      filterOptions: FilterOptions;
      selectedService: Service;
      shouldLimitPerDay?: boolean;
    },
  ) {
    try {
      let from;
      const todayLocalDateTime = getTodayLocalDateTimeStartOfDay(
        selectedTimezone!,
      );
      if (new Date(toAsLocalDateTime) < new Date(todayLocalDateTime)) {
        return {};
      } else {
        from =
          new Date(todayLocalDateTime) > new Date(fromAsLocalDateTime)
            ? todayLocalDateTime
            : fromAsLocalDateTime;
      }
      const availabilityCalendarRequest: QueryAvailabilityRequest =
        this.buildQueryAvailabilityRequest({
          from,
          to: toAsLocalDateTime,
          selectedTimezone,
          filterOptions,
          selectedService,
          shouldLimitPerDay,
        });
      const availabilityRes = await this.getSlotsAvailability(
        this.flowAPI.httpClient,
        availabilityCalendarRequest,
      );
      return availabilityRes;
    } catch (e: any) {
      console.error('availability error: ', e);
      this.reportError(e);
    }
  }

  private buildQueryAvailabilityRequest({
    from,
    to,
    selectedTimezone,
    filterOptions,
    selectedService,
    shouldLimitPerDay = false,
    getNextAvailableSlot = false,
  }: {
    from: string;
    to: string;
    selectedTimezone: string;
    filterOptions: FilterOptions;
    selectedService: Service;
    shouldLimitPerDay?: boolean;
    getNextAvailableSlot?: boolean;
  }): QueryAvailabilityRequest {
    return {
      timezone: selectedTimezone,
      ...(shouldLimitPerDay ? { slotsPerDay: 1 } : {}),
      query: {
        filter: {
          serviceId: [`${selectedService.id}`],
          startDate: from,
          endDate: to,
          bookable: true,
          ...(filterOptions.STAFF_MEMBER?.length > 0
            ? { resourceId: filterOptions.STAFF_MEMBER }
            : {}),
          ...(filterOptions.LOCATION?.length > 0
            ? { 'location.businessLocation.id': filterOptions.LOCATION }
            : {}),
          openSpots: { $gte: '1' },
        },
        ...(getNextAvailableSlot ? { cursorPaging: { limit: 1 } } : {}),
      },
    };
  }

  async getSlotsAvailability(
    httpClient: IHttpClient,
    queryAvailabilityRequest: QueryAvailabilityRequest,
  ): Promise<QueryAvailabilityResponse> {
    const availability = await httpClient.request(
      queryAvailability(queryAvailabilityRequest),
    );
    return availability.data;
  }

  async createBooking(
    httpClient: IHttpClient,
    createBookingRequest: CreateBookingRequest,
  ): Promise<CreateBookingResponse> {
    const createBookingResponse = await httpClient.request(
      createBooking(createBookingRequest),
    );
    return createBookingResponse.data;
  }

  async checkoutBooking({
    httpClient,
    slot,
    contactDetails,
    additionalFields,
    totalParticipants,
    sendSmsReminder,
    selectedPaymentOption,
    participantsChoices,
  }: {
    httpClient: IHttpClient;
    slot: V2BookedSlot;
    contactDetails: V2ContactDetails;
    additionalFields: any[];
    totalParticipants: number;
    sendSmsReminder?: boolean;
    selectedPaymentOption: any;
    participantsChoices?: any | undefined;
  }): Promise<CreateCheckoutResponse | CreateOrderResponse> {
    try {
      const booking: V2Booking = {
        v2Availability: true,
        ...(participantsChoices
          ? { participantsChoices }
          : { totalParticipants }),
        bookingSource: {
          actor: BookingSourceActor.CUSTOMER,
          platform: BookingSourcePlatform.WEB,
        },
        contactDetails,
        bookedEntity: {
          slot,
        },
        additionalFields,
        selectedPaymentOption,
      };

      const participantNotification = {
        notifyParticipants: true,
      };
      const createBookingRequest: any = {
        // CreateBookingRequest
        booking,
        participantNotification,
        sendSmsReminder,
        flowControlSettings: {},
      };

      const createBookingResponse = await this.createBooking(
        httpClient,
        createBookingRequest,
      );
      console.log('createBookingResponse:', createBookingResponse);

      const bookingId = createBookingResponse!.booking!.id!;
      const checkoutObject: CreateCheckoutRequest = {
        channelType: ApiChannelType.WEB,
        lineItems: [
          {
            quantity: 1,
            catalogReference: {
              catalogItemId: bookingId,
              appId: BOOKINGS_APP_DEF_ID,
            },
          },
        ],
        checkoutInfo: {
          billingInfo: {
            contactDetails: this.mapContactDetails(contactDetails),
          },
          buyerInfo: {
            email: contactDetails.email || undefined,
          },
        },
      };
      const createCheckoutResponse = await this.ecomCheckoutServer
        .CheckoutService()({
          Authorization: this.authorization,
        })
        .createCheckout(checkoutObject);
      if (this.isOfflineCheckoutFlow(createCheckoutResponse)) {
        const createOrderResponse = await this.ecomCheckoutServer
          .CheckoutService()({
            Authorization: this.authorization,
          })
          .createOrder({
            id: createCheckoutResponse?.checkout?.id,
          });
        return createOrderResponse;
      } else {
        return createCheckoutResponse;
      }
    } catch (error: any) {
      console.log('Error: ', error);
      throw mapCheckoutBookingError(error?.response);
    }
  }

  async isBookingsOnEcom(): Promise<boolean> {
    try {
      const { data: siteRolloutStatusResponse } =
        await this.flowAPI.httpClient.request(getSiteRolloutStatus({}));
      return siteRolloutStatusResponse.siteRolloutStatus!
        .isBookingPlatformReady!;
    } catch (e: any) {
      this.reportError(e);
      throw EmptyStateType.CANNOT_FETCH_ECOM_ROLLOUT_STATUS;
    }
  }

  async getServiceVariants(
    serviceId: string,
  ): Promise<ServiceOptionsAndVariants | undefined> {
    try {
      const {
        data: { serviceOptionsAndVariantsList },
      } = await this.flowAPI.httpClient.request(
        queryServiceOptionsAndVariants({
          query: {
            filter: {
              serviceId,
            },
          },
        }),
      );
      return serviceOptionsAndVariantsList?.[0];
    } catch (e) {
      console.log(e);
      return undefined;
    }
  }

  private mapContactDetails(contactDetails: any): any {
    if (contactDetails.lastName) {
      return contactDetails;
    }
    const nameParts = contactDetails?.firstName?.split(' ');
    const firstName = nameParts?.[0];
    const lastName = nameParts?.[1];
    return {
      ...contactDetails,
      ...(firstName && { firstName }),
      ...(lastName && { lastName }),
    };
  }

  private isOfflineCheckoutFlow(
    createCheckoutResponse: CreateCheckoutResponse,
  ) {
    const payNowAmount =
      createCheckoutResponse?.checkout?.payNow?.total?.amount;
    return payNowAmount && Number(payNowAmount) === 0;
  }
}

export const generateLineItemId = (index = 1) => {
  // currently support up to 10 line items for simplicity
  return `00000000-0000-0000-0000-00000000000${index}`;
};
