import { ServiceLocation } from '@wix/bookings-uou-types';
import { LocationLocationType, TimeSlotLocation } from '@wix/ambassador-bookings-availability-v1-slot-availability/types';

export type Location = {
  id?: string | null;
  name?: string | null;
  address?: string | null;
  locationType: LocationLocationType;
};

export const mapServiceLocationToLocation = (
  location: ServiceLocation,
): Location => {
  return {
    id: location.businessLocation?.id,
    name: location.businessLocation?.name,
    ...{
      address:
        location?.locationText ??
        location?.businessLocation?.address?.formattedAddress,
    },
    locationType: location?.type as any,
  };
};

export const mapSlotLocationToLocation = (
  location?: TimeSlotLocation,
): Location => {
  return {
    id: location?.id,
    name: location?.name,
    address: location?.formattedAddress,
    locationType: location!.locationType!,
  };
};
