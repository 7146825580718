import {
  bookingsUouQuickBookSelectDateTime,
  bookingsBookItClick,
  bookingsWidgetPageLoaded,
} from '@wix/bi-logger-wixboost-ugc/v2';

export const reportQuickBookSelectDateTime = (biLogger, params) => {
  biLogger.report(bookingsUouQuickBookSelectDateTime(params));
};

export const reportBookItClick = (biLogger, params) => {
  biLogger.report(bookingsBookItClick(params));
};

export const reportWidgetPageLoaded = (biLogger, params) => {
  biLogger.report(bookingsWidgetPageLoaded(params));
};
