import { ConsultantsWidgetComponentIds as ComponentIds } from './config/constants';

export const getComponents = ($w) => ({
  consultantsWidget: $w(ComponentIds.consultantsWidget),
  locationDropdownErrorMessge: $w(ComponentIds.locationDropdownErrorMessge),
  datePickerErrorMessage: $w(ComponentIds.datePickerErrorMessage),
  timeDropdownErrorMessage: $w(ComponentIds.timeDropdownErrorMessage),
  staffDropdownErrorMessage: $w(ComponentIds.staffDropdownErrorMessage),
  formRepeater: $w(ComponentIds.formRepeater),
  multiStateForm: $w(ComponentIds.multiStateForm),
  shortInputTitle: $w(ComponentIds.shortInputTitle),
  longInputTitle: $w(ComponentIds.longInputTitle),
  formCheckbox: $w(ComponentIds.formCheckbox),
  phoneTitle: $w(ComponentIds.phoneTitle),
  countryTitle: $w(ComponentIds.countryTitle),
  serviceTitle: $w(ComponentIds.serviceTitle),
  serviceDuration: $w(ComponentIds.serviceDuration),
  servicePrice: $w(ComponentIds.servicePrice),
  staffName: $w(ComponentIds.staffName),
  serviceLocation: $w(ComponentIds.serviceLocation),
  timezoneDropdown: $w(ComponentIds.timezoneDropdown),
  locationDropdown: $w(ComponentIds.locationDropdown),
  locationDropdownContainer: $w(ComponentIds.locationDropdownContainer),
  datePicker: $w(ComponentIds.datePicker),
  slotsDropdown: $w(ComponentIds.slotsDropdown),
  staffDropdown: $w(ComponentIds.staffDropdown),
  formTilte: $w(ComponentIds.formTilte),
  formDescription: $w(ComponentIds.formDescription),
  widgetMultiStateBox: $w(ComponentIds.widgetMultiStateBox),
  bookButton: $w(ComponentIds.bookButton),
  multiStateAvailability: $w(ComponentIds.multiStateAvailability),
  paymentOptionsDropdown: $w(ComponentIds.paymentOptionsDropdown),
  dropdown14: $w(ComponentIds.dropdown14),
  formSection: $w(ComponentIds.formSection),
  errorToast: $w(ComponentIds.errorToast),
  topErrorNotification: $w(ComponentIds.topErrorNotification),
  formErrorNotification: $w(ComponentIds.formErrorNotification),
  successNotification: $w(ComponentIds.successNotification),
  shortInputErrorMessage: $w(ComponentIds.shortInputErrorMessage),
  longInputErrorMessage: $w(ComponentIds.longInputErrorMessage),
  phoneInputErrorMessage: $w(ComponentIds.phoneInputErrorMessage),
  checkboxErrorMessage: $w(ComponentIds.checkboxErrorMessage),
  text11: $w(ComponentIds.text11),
  text10: $w(ComponentIds.text10),
  locaitionContainer: $w(ComponentIds.box10),
  staffContainer: $w(ComponentIds.box12),
  reminderCheckbox: $w(ComponentIds.reminderCheckbox),
  shortInput: $w(ComponentIds.shortInput),
  longInput: $w(ComponentIds.longInput),
  phoneInput: $w(ComponentIds.phoneInput),
  phoneCodeDropdown: $w(ComponentIds.phoneCodeDropdown),
  noServicesText: $w(ComponentIds.noServicesText),
  staffNameDivider: $w(ComponentIds.staffNameDivider),
  priceDivider: $w(ComponentIds.priceDivider),
  onlineBadge: $w(ComponentIds.onlineBadge),
  datePickerLabel: $w(ComponentIds.datePickerLabel),
  box10: $w(ComponentIds.box10),
  box12: $w(ComponentIds.box12),
  reminderCheckboxLabel: $w(ComponentIds.reminderCheckboxLabel),
  reminderCheckboxContainer: $w(ComponentIds.reminderCheckboxContainer),
  customVariantsDropdown: $w(ComponentIds.customVariantsDropDown),
  customVariantsDropdownErrorMessage: $w(
    ComponentIds.customVariantsDropdownErrorMessage,
  ),
});
