import {
  GenericErrorType,
  CreateBookingErrorType,
  CouponsErrorType,
  FormErrors,
  PlatformServerError,
  BookingsErrorCodes,
  BookErrorType,
  AvailabilityErrorCodes,
} from '../types/types';

export type BookingsServerError = {
  details: {
    applicationError: {
      code: string;
    };
  };
};

export const mapBookingsServerError = (error: BookingsServerError | any) => {
  const serverErrorCode = error?.details?.applicationError?.code;
  const mappedErrorByType =
    mappedNotificationErrorCodesToErrorTypes[
      serverErrorCode as BookingsErrorCodes
    ];
  if (mappedErrorByType) {
    return mappedErrorByType;
  }
  return GenericErrorType.GENERIC_BOOK_ERROR;
};

export const mapCheckoutBookingError = (error: PlatformServerError) => {
  const errorCode = error?.details?.applicationError?.code;
  if (
    isErrorOfType(CouponsErrorType, errorCode as FormErrors) ||
    isErrorOfType(CreateBookingErrorType, errorCode as FormErrors)
  ) {
    return errorCode;
  }
  return GenericErrorType.GENERIC_BOOK_ERROR;
};

export const mapAvailabilityServerError = (error) => {
  const serverErrorCode = error?.code;
  const mappedErrorByType =
    mappedNotificationErrorCodesToErrorTypes[
      serverErrorCode as AvailabilityErrorCodes
    ];
  if (mappedErrorByType) {
    return mappedErrorByType;
  }
  return GenericErrorType.GENERAL_ERROR; // change to AvailabilityErrorCodes.BOOKINGS_SYSTEM_ERROR
};

const isErrorOfType = <ErrorType>(errorType: ErrorType, error: FormErrors) => {
  // @ts-expect-error
  return Object.values(errorType).some(
    (expectedError) => error === expectedError,
  );
};

export const mappedNotificationErrorCodesToErrorTypes = {
  [BookingsErrorCodes.BOOKING_POLICY_VIOLATION]:
    BookErrorType.BOOKING_POLICY_VIOLATION,
  [BookingsErrorCodes.SLOT_NOT_AVAILABLE]: BookErrorType.SLOT_NOT_AVAILABLE,
  [BookingsErrorCodes.CANT_BOOK_CANCELED_SESSION]:
    BookErrorType.CANT_BOOK_CANCELED_SESSION,
  [BookingsErrorCodes.SESSION_TIME_MISMATCH]:
    BookErrorType.SESSION_TIME_MISMATCH,
  [BookingsErrorCodes.EXCEEDED_ALLOWED_MAX_NUMBER_OF_PARTICIPANTS]:
    GenericErrorType.EXCEEDED_ALLOWED_MAX_NUMBER_OF_PARTICIPANTS,
  [BookingsErrorCodes.SESSION_CAPACITY_EXCEEDED]:
    BookingsErrorCodes.SESSION_CAPACITY_EXCEEDED,
  [AvailabilityErrorCodes.BOOKINGS_SYSTEM_ERROR]:
    GenericErrorType.BOOKINGS_SYSTEM_ERROR,
};
