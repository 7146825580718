import { Optional } from '../types/types';
import { BulkResponse } from '@wix/ambassador-services-catalog-server/types';

export const getFirstIndividualService = (
  catalogData: Optional<BulkResponse>,
) => {
  let serviceIndex;
  const selectedService = catalogData?.responseServices?.services?.find(
    (service, index) => {
      const schedule = service.schedules?.[0]!;
      serviceIndex = index;
      return schedule.status === 'CREATED';
    },
  );
  return {
    service: selectedService,
    serviceIndex,
  };
};
