import { DurationMapperOptions } from '../../../utils/mappers/durationMapper';

export enum ConsultantsWidgetComponentIds {
  consultantsWidget = '#box1',
  locationDropdownErrorMessge = '#errorMessage1',
  datePickerErrorMessage = '#errorMessage3',
  staffDropdownErrorMessage = '#requiredField1',
  customVariantsDropdownErrorMessage = '#requiredField2',
  timeDropdownErrorMessage = '#errorMessage5',
  formRepeater = '#formRepeater',
  multiStateForm = '#multiStateForm',
  shortInputTitle = '#shortInputTitle',
  longInputTitle = '#longInputTitle',
  phoneTitle = '#phoneTitle',
  countryTitle = '#countryTitle',
  serviceTitle = '#serviceTitle',
  serviceDuration = '#serviceDuration',
  servicePrice = '#servicePrice',
  staffName = '#staffName',
  serviceLocation = '#serviceLocation',
  timezoneDropdown = '#timezoneDropdown',
  locationDropdownContainer = '#locationDropdownContainer',
  locationDropdown = '#locationDropdown',
  datePicker = '#datePicker',
  slotsDropdown = '#slotsDropdown',
  staffDropdown = '#staffDropdown',
  formTilte = '#formTilte',
  formDescription = '#formDescription',
  widgetMultiStateBox = '#widgetMultiStateBox',
  bookButton = '#bookButton',
  multiStateAvailability = '#multiStateAvailability',
  paymentOptionsDropdown = '#paymentOptionsDropdown',
  dropdown14 = '#dropdown14',
  reminderCheckbox = '#reminderCheckbox',
  reminderCheckboxLabel = '#text38',
  reminderCheckboxContainer = '#box49',
  formSection = '#formSection',
  errorToast = '#errorToast',
  topErrorNotification = '#topErrorNotification',
  formErrorNotification = '#formErrorNotification',
  successNotification = '#successNotification',
  shortInputErrorMessage = '#shortInputErrorMessage',
  longInputErrorMessage = '#box52',
  phoneInputErrorMessage = '#phoneInputErrorMessage',
  checkboxErrorMessage = '#checkboxErrorMessage',
  text11 = '#text11',
  text10 = '#text10',
  box10 = '#box10',
  box12 = '#box51',
  formCheckbox = '#formCheckbox',
  shortInput = '#input2',
  longInput = '#textBox1',
  phoneInput = '#input3',
  phoneCodeDropdown = '#dropdown13',
  noServicesText = '#text32',
  staffNameDivider = '#text6',
  priceDivider = '#text4',
  onlineBadge = '#onlineBadge1',
  datePickerLabel = '#text39',
  customVariantsDropDown = '#customVariantsDropDown',
}

export enum FormStateTypes {
  SHORT_INPUT = 'shortInput',
  LONG_INPUT = 'longInput',
  CHECKBOX = 'checkbox',
  PHONE_VALIDATION = 'phoneValidation',
}

export enum WidgetStateTypes {
  WITH_SERVICES = 'withServices',
  NOֹֹֹ_SERVICES = 'noServices',
  LOADING = 'loading',
}

export enum AvailabilityStateTypes {
  NO_AVAILABILITY = 'NoAvailability',
  WITH_AVAILABILITY = 'WithAvailability',
}

export const options: DurationMapperOptions = {
  hourUnit: 'hr',
  minuteUnit: 'min',
  hourAriaUnit: 'hours',
  minuteAriaUnit: 'minutes',
};

export enum paymentOptions {
  WIX_PAY_ONLINE = 'wixPay_Online',
  WIX_PAY_OFFLIN = 'wixPay_Offline',
}
export enum Panels {
  SERVICES_PANEL_ID = '13d21c63-b5ec-5912-8397-c3a5ddb27a97',
}

export enum WidgetGfppTypes {
  DISPLAY_ELEMENTS = 'displayElements',
  SELECT_SERVICE = 'selectService',
}

export enum ReferalInfoTypes {
  APPOINTMENT_FORM = 'appointment_form',
}

export const compRefIdSuffix = '_r_comp-kxsoxulf';
