import { createSlice } from '@reduxjs/toolkit';
import { WidgetStateTypes } from '../../components/Consultants Widget/config/constants';

const initialState = {
  widgetState: WidgetStateTypes.LOADING,
};

const consultantSlice = createSlice({
  name: 'consultantWidget',
  initialState,
  reducers: {
    updateWidgetState: (state, action) => {
      state.widgetState = action.payload;
    },
  },
});

export const { updateWidgetState } = consultantSlice.actions;

export default consultantSlice.reducer;
