import { MessageType, SubmissionValue } from '@wix/forms-ui/types';
import { phoneRegex, emailRegex } from '@wix/wix-js-validations';

export const doesFieldHasValue = (value: string) => value && value.length > 0;

export const validatePhone = (prefix: string, phone: string) => {
  if (
    doesFieldHasValue(prefix) &&
    doesFieldHasValue(phone) &&
    !phoneRegex.test(prefix + phone)
  ) {
    return MessageType.INVALID_PATTERN;
  }
  return true;
};

export const validateEmail = (email: SubmissionValue) => {
  if (isValidEmail(email)) {
    return true;
  }
  return MessageType.INVALID_PATTERN;
};

export const isValidEmail = (email: SubmissionValue) => {
  return doesFieldHasValue(email as string) && emailRegex.test(email as string);
};

export const validatePhoneNumber = (phone: string) => {
  if (!phoneRegex.test(phone)) {
    return MessageType.INVALID_PATTERN;
  }
  return true;
};

export const validateFormSection = (formSubmission, service) => {
  const serviceForm = service!.formSchema.fields;
  const misingFieldIds = {};
  serviceForm.forEach((field) => {
    if (
      field.renderInfo?.validationProperties?.required &&
      !formSubmission[field.externalId!]
    ) {
      misingFieldIds[field.externalId] = field.externalId;
    }
  });
  return misingFieldIds;
};
